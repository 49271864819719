import React, { useEffect, useState, useContext, useRef } from 'react';
import Header from '../../template/Header';
import './Business.css';
import HeadingSection from "../../components/headingSection/headingSection";
import { Col, Row, Button } from "react-bootstrap";
import { useMediaQueryHook } from "../../hooks/mediaQueryHook";
import "react-image-gallery/styles/css/image-gallery.css";
import Footer from "../../components/footer/footer";
import { ChangeLanguageContext } from '../../contexts/changeLanguageContext';
import { Link } from 'react-router-dom';
import ImageGallery from "react-image-gallery";
import { getDataCondo, getDataCondoDetail } from "../../api/business_api"
import { submitEnquiry } from '../../api/enquiry_api';
import CardSlider2 from "../../components/cardSlide/Slide2"
import CardSlider5 from "../../components/cardSlide/Slick5"
import CardSlider7 from "../../components/cardSlide/Slick7"
import CardSlider4 from "../../components/cardSlide/Slick4"
import Swal from "sweetalert2";
import {formatDate, formatDateTH} from "../../utils/formatDate";
import { getDataPromoPrivi } from '../../api/promo_privi_api';
import { useTranslation } from 'react-i18next';
import ScrollAnimation from 'react-animate-on-scroll';
import "animate.css/animate.compat.css"
import ScrollToTopButton from '../../components/scrollTopButton/ScrollTopButton';
import { isVideoFile } from '../../utils/functions';

function Condominium() {
    const { viewportSize } = useMediaQueryHook();
    const notContentLeftInMobile = viewportSize?.width <= 1024 ? false : true;
    const { t } = useTranslation();
    const { lang } = useContext(ChangeLanguageContext);

    const [dataId, setDataId] = useState(null);
    const [typePromo ,setTypePromo] = useState('');
    const [activeTab, setActiveTab] = useState('forRent');
    const [type, setType] = useState('');
    const [titleDesc, setTitleDesc] = useState('');
    const [txtDesc, setTxtDesc] = useState('');
    const [aspVirtualLink, setAspVirtualLink] = useState();
    const [txtDescDetail, setTxtDescDetail] = useState();
    const [txtSpec,setTxtSpec] = useState();
    const [detailSpec, setDetailSpec] = useState();
    const [imgSpec,setImgSpec] = useState();
    const [txtFac,setTxtFac] = useState();
    const [detailFac, setDetailFac] = useState();
    const [imgFac, setImgFac] = useState('');
    const [imgGallery, setImgGallery] = useState([]);
    const [imgPlan, setImgPlan] = useState([]);
    const [titleRoom1, setTitleRoom1] = useState();
    const [txtRoom1, setTxtRoom1] = useState();
    const [imgRoom1, setImgRoom1] = useState([]);
    const [titleRoom2, setTitleRoom2] = useState();
    const [txtRoom2, setTxtRoom2] = useState();
    const [imgRoom2, setImgRoom2] = useState([]);
    const [titleRoom3, setTitleRoom3] = useState();
    const [txtRoom3, setTxtRoom3] = useState();
    const [imgRoom3, setImgRoom3] = useState([]);

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    const firstBanner = [{
        original: "https://dev-asp.verkoffice.com/assets/images/banner-condo.png",
        thumbnail: "https://picsum.photos/id/1018/250/150/",
        description1: "RECOGNIZED",
        description2: "AS THE PROMINENT",
        description3: "BUSINESS ADDRESS",
        isVideo: "",
      }]
      const [heroBanner, setHeroBanner] = useState(firstBanner);

    const [dataAppForm, setDataAppForm] = useState({
        fullName: '',
        tel: '',
        email: '',
    });

    const [listDataPromo, setListDataPromo] = useState([
        {
            idPromo: '',
            typePromo: '',
            images: '/assets/images/offices/Group20.png',
            txtTitle: 'CONRAD BANKOK',
            discount: '20',
            txtDesc: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
            // effectiveDate: '13 Jun 24',
            // endDate: '17 Jun 24',
            publishDate: '13 Jun 24',
            txtDetail: '*According to the specified conditions',
            shareFB: '',
            shareX: '',
            shareXtext: 'All Season',
            shareLine: '',
            shareLineText: 'All Season',
            link: '',
            rank:'',
            coverImgs: ''
        },
        {
            idPromo: '',
            typePromo: '',
            images: '/assets/images/offices/Group21.png',
            txtTitle: 'CONDOMINIUM',
            discount: '20',
            txtDesc: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
            // effectiveDate: '13 Jun 24',
            // endDate: '17 Jun 24',
            publishDate: '13 Jun 24',
            txtDetail: '*According to the specified conditions',
            shareFB: '',
            shareX: '',
            shareXtext: 'All Season',
            shareLine: '',
            shareLineText: 'All Season',
            link: '',
            rank:'',
            coverImgs: ''
        },
        {
            idPromo: '',
            typePromo: '',
            images: '/assets/images/offices/Group22.png',
            txtTitle: 'CONDO FOR RENT',
            discount: '20',
            txtDesc: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
            // effectiveDate: '13 Jun 24',
            // endDate: '17 Jun 24',
            publishDate: '13 Jun 24',
            txtDetail: '*According to the specified conditions',
            shareFB: '',
            shareX: '',
            shareXtext: 'All Season',
            shareLine: '',
            shareLineText: 'All Season',
            link: '',
            rank:'',
            coverImgs: ''
        },
        {
            idPromo: '',
            typePromo: '',
            images: '/assets/images/offices/Group23.png',
            txtTitle: 'CONDO FOR SALE',
            discount: '20',
            txtDesc: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
            // effectiveDate: '13 Jun 24',
            // endDate: '17 Jun 24',
            publishDate: '13 Jun 24',
            txtDetail: '*According to the specified conditions',
            shareText: 'All Season',
            link: '',
            rank:'',
            coverImgs: ''
        },
    ]);

    const [listGallery, setListGallery] = useState([
        {
            images: ''
        }
    ]);

    const [listPlan, setListPlan] = useState([
        {
            images: ''
        }
    ]);
  
    const [tableParam, setTableParam] = useState({
      page_number:1,
      data_per_page:5,
    }); //กำหนด column เริ่มต้นในการ sort
    const [totalNumber, setTotalNumber] = useState(0)
    useEffect(()=>{
      fetchingDataPromo()
    },[])

    const galleryRef = useRef(null);
    const planRef = useRef(null);
    
    const imageGallery = imgGallery.map(gallery => ({
        original: gallery.original,
    }));
    const imagePlan = imgPlan.map(plans => ({
        original: plans.original,
    }));

    const renderItem2 = (item) => {
        return (
            <div className='image-gallery-item pd-bn'>
                <img src={item.original} alt={item.original} className='im-banner' />
            </div>
        );
    };
    const renderItem3 = (item) => {
        return (
            <div className='image-gallery-item'>
                <img src={item.original} alt={item.original} className='im-banner' />
            </div>
        );
    };

    const CustomLeftNav = ({ onClick, disabled }) => (
        <button
            type="button"
            className="custom-arrow custom-left-arrow"
            disabled={disabled}
            onClick={onClick}
            aria-label="Previous Slide"
        />
    );
    
    const CustomRightNav = ({ onClick, disabled }) => (
        <button
            type="button"
            className="custom-arrow custom-right-arrow"
            disabled={disabled}
            onClick={onClick}
            aria-label="Next Slide"
        />
    );

    const [vrMode, setVrMode] = useState(false);

    useEffect(() => {
        // Event listener to handle scroll and toggle VR mode
        const handleScroll = () => {
        const clickVrElement = document.querySelector('.click-vr');
        const iframe = document.querySelector('iframe');
        if (clickVrElement && iframe) {
            const { top, bottom } = clickVrElement.getBoundingClientRect();
            if (top >= 0 && bottom <= window.innerHeight) {
            // Click VR element is fully visible in the viewport
            // setVrMode(true);
            } else {
            // Click VR element is not fully visible in the viewport
            setVrMode(false);
            }
        }
        };

        // Add scroll event listener
        window.addEventListener('scroll', handleScroll);

        // Clean up the event listener on component unmount
        return () => {
        window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleClickVr = () => {
        // Toggle VR mode on click
        setVrMode(!vrMode);
    };

    const validateEmail = (email) => {
        // Regular expression for email validation
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return emailRegex.test(email);
    };

    async function alertValidate(title) {
        const res = await Swal.fire({
            iconHtml: '<img src="https://img2.pic.in.th/pic/Group-2966.png">',
            title: title,
        });
        if (res.isConfirmed) {
            return;
        } else {
        return;
        }
    }

    const submitData = async () =>{
        try {
            if (validateEmail(dataAppForm.email)) {
                const formatedBodyToServer = {
                    type: 'condominum',
                    fullname: dataAppForm.fullName,
                    phonenumber: dataAppForm.tel,
                    email: dataAppForm.email,
                }
                if (
                    !formatedBodyToServer.fullname ||
                    formatedBodyToServer.phonenumber.length !== 10 ||
                    !formatedBodyToServer.email
                ) {
                    await alertValidate("กรุณากรอกข้อมูลให้ครบถ้วน");
                } else {
                    await Swal.fire({
                        title: "Confirm Data Save",
                        text: "ยืนยันการบันทึกข้อมูล",
                        iconHtml: '<img src="https://img5.pic.in.th/file/secure-sv1/ic-save.png">',
                        showConfirmButton: true,
                        showCancelButton: true,
                        reverseButtons: true,
                        confirmButtonText: "CONFIRM",
                        cancelButtonText: "CANCEL",
                    }).then((result) => {
                        if (result.isConfirmed) {
                            (async () => {
                                const response = await submitEnquiry(null, lang=='en-US'?'en':lang, formatedBodyToServer);
                                    if (response?.status == 200) {
                                        Swal.fire({
                                        icon: "success",
                                        title: "Saved",
                                        text: "บันทึกเรียบร้อยแล้ว",
                                        }).then((result) => {
                                        if (result.isConfirmed) {
                                            window.location.reload();
                                        }
                                        });
                                    }
                                    else{
                                        Swal.fire({
                                            icon: "error",
                                            title: "Error",
                                        }).then((result) => {
                                            if (result.isConfirmed) {
                                            window.location.reload();
                                            }
                                        });
                                    }
                                })();
                        }
                    });
                    }
                }
                else {
                    alertValidate("กรุณากรอกรูปแบบอีเมลให้ถูกต้อง")
                }
        } catch (error) {
            console.log('found error ', error)
        }
    }
    
    const renderItem = (item) => {
            return (
            <div className='image-gallery-item'>
                {item.isVideo ? (
                <div className="video-wrapper">
                    <video controls className='im-banner'>
                    <source src={item.original} type="video/mp4" />
                    Your browser does not support the video tag.
                    </video>
                </div>
                ) : (
                <img src={item.original} alt={item.original} className='im-banner' />
                )}
                <div className='image-gallery-description'>
                    <p className='mb-1 mb-md-2 fontBold font-size09 colorWhite'><div dangerouslySetInnerHTML={{ __html: item.description1}}></div></p>
                    <p className='mb-2 fontLight font-size06 colorWhite'><div dangerouslySetInnerHTML={{ __html: item.description2}}></div></p>
                    <p className='mb-1 fontLight font-size06 colorWhite'><div dangerouslySetInnerHTML={{ __html: item.description3}}></div></p>
                    <p className='mb-2 bt-line-green'></p>
                </div>
            </div>
            );
    };  

    const fetchingData = async (language) => {
        try {
          let type = 'for_rent'
            if(activeTab=='forRent'){
                type = 'for_rent'
            }
            else if(activeTab=='forSale'){
                type = 'for_sale'
            }
                const infoDefault = {
                    type:type
            }
            const result = await getDataCondo(infoDefault, language);
            const banners = result?.data?.data?.banner?.data.map((item) => ({
                original: item.data,
                thumbnail: item.data,
                description1: item.supertext,
                description2: item.description,
                description3: '',
                isVideo: isVideoFile(item.data),
            }));
            let bannerConcat = firstBanner.concat(banners);
            setHeroBanner(bannerConcat);
            setType(result?.data?.data?.content?.type);
            setTitleDesc(result?.data?.data?.content?.title);
            setTxtDesc(result?.data?.data?.content?.condo_desc);
            setAspVirtualLink(result?.data?.data?.content?.virtual_link);
            setTxtSpec(result?.data?.data?.content?.specification_title);
            setDetailSpec(result?.data?.data?.content?.specification_description);
            setImgSpec(result?.data?.data?.content?.spec_img[0]);
            setTxtFac(result?.data?.data?.content?.facilities_title);
            setDetailFac(result?.data?.data?.content?.facilities_description);
            setImgFac(result?.data?.data?.content?.fac_img[0]);
            
            const gallerys = result?.data?.data?.content?.gallery.map((item) => ({
                images: item,
            }));
            setListGallery(gallerys);

            const plans = result?.data?.data?.content?.plan_img.map((item) => ({
                images: item,
            }));
            setListPlan(plans);

        } catch (error) {
            console.log('found error', error);
        }
    };

    const fetchingDataDetail = async (language) => {
        try {
          let type = 'for_rent'
            if(activeTab=='forRent'){
                type = 'for_rent'
            }
            else if(activeTab=='forSale'){
                type = 'for_sale'
            }
                const infoDefault = {
                    type:type
            }
            const result = await getDataCondoDetail(infoDefault, language);
            setType(result?.data?.data?.type);
            setTxtDescDetail(result?.data?.data?.description);
            setTitleRoom1(result?.data?.data?.title_room1);
            setTitleRoom2(result?.data?.data?.title_room2);
            setTitleRoom3(result?.data?.data?.title_room3);
            setTxtRoom1(result?.data?.data?.room1_description);
            setTxtRoom2(result?.data?.data?.room2_description);
            setTxtRoom3(result?.data?.data?.room3_description);

            const gallerysRoom1 = result?.data?.data?.room1_gallery.map((item) => ({
                images: item,
            }));
            setImgRoom1(gallerysRoom1);

            const gallerysRoom2 = result?.data?.data?.room2_gallery.map((item) => ({
                images: item,
            }));
            setImgRoom2(gallerysRoom2);

            const gallerysRoom3 = result?.data?.data?.room3_gallery.map((item) => ({
                images: item,
            }));
            setImgRoom3(gallerysRoom3);

        } catch (error) {
            console.log('found error', error);
        }
    };

    const fetchingDataPromo = async (language) => {
        try {
            let type = 'all'
           
                const infoDefault = {
                    type:type,
                    data_per_page: 5
            }
            setTypePromo(infoDefault.type);
            const result = await getDataPromoPrivi(infoDefault, language);
            setDataId(result?.data?.data?._id)
            const infoList = result?.data?.data?.contentdata;
            if (infoList && infoList.length > 0) {
              let updatedInfoListDatas = infoList.map(detail => {
                  return {
                        idPromo: detail._id,
                        images: detail.imgs?.length>0?detail.imgs[0]:'',
                        txtTitle: detail.title,
                        discount: detail.discount,
                        discountDate: language == 'th' ? formatDateTH(detail.discount_date) : formatDate(detail.discount_date),
                        // effectiveDate: language == 'th' ? formatDateTH(detail.effective_date) : formatDate(detail.effective_date),
                        // endDate: language == 'th' ? formatDateTH(detail.end_date) : formatDate(detail.end_date),
                        publishDate: language == 'th' ? formatDateTH(detail.publish_date) : formatDate(detail.publish_date),
                        txtDesc: detail.description,
                        txtDetail: detail.note,
                        link: detail.link,
                        rank: detail.rank,
                        coverImgs: detail.cover_imgs?.length>0?detail.cover_imgs[0]:'',
                        shareLineText: detail?.shareLineText,
                        shareXtext: detail?.shareXtext,
                        shareText: detail?.shareXtext,
                  };
              });
            //   updatedInfoListDatas.sort((a, b) => {
            //     if (a.rank !== b.rank) {
            //       return a.rank - b.rank;
            //     }
            //     return new Date(b.effectiveDate) - new Date(a.effectiveDate);
            //   });
              setListDataPromo(updatedInfoListDatas);
              setTotalNumber(5)
          } else {
                // Handle case when detail_list is empty
                // You may want to set an empty array or handle it differently
                setListDataPromo([]);
            }
        } catch (error) {
            console.log('found error', error);
        }
    };
    
    useEffect(() => {
        fetchingData(lang === 'en-US' ? 'en' : lang);
        fetchingDataDetail(lang === 'en-US' ? 'en' : lang);
        fetchingDataPromo(lang === 'en-US' ? 'en' : lang);
    }, [lang]);

    useEffect(() => {
        fetchingData(lang === 'en-US' ? 'en' : lang);
        fetchingDataDetail(lang === 'en-US' ? 'en' : lang);
        fetchingDataPromo(lang === 'en-US' ? 'en' : lang);
    }, [activeTab]);

    return (
        <div>
            <style>
                {`
                    ul.menu li button#business {
                    color: #3AF823 !important;
                    text-decoration: underline;
                    }
                    video.im-banner {
                        width: 100% !important;
                        height: 600px !important;
                    }
                    .mgt-6 {
                        margin-top: -6px !important;
                    }
                    .click-vr {
                        height: 616px !important;
                    }
                        .custom-left-arrow {
                        left: 0 !important;
                        margin-left: 45px !important;
                    }
                    .custom-right-arrow {
                        right: 0 !important;
                    }
                    .custom-arrow {
                        top: 50% !important;
                        bottom: unset !important;
                        transform: translate(-50%, -50%) !important;
                    }
                    @media screen and (max-width: 1440px) {
                        .click-vr {
                            height: 516px !important;
                        }
                    }
                    @media screen and (max-width: 1220px) {
                        .click-vr {
                            height: 350px !important;
                        }
                    }
                    @media (max-width: 991px){
                        .custom-arrow {
                            width: 40px !important;
                            height: 40px !important;
                        }
                        .order-text01 {
                            order: 2 !important;
                        }
                        .order-img01 {
                            order: 1 !important;
                        }
                        .order-text02 {
                            order: 1 !important;
                        }
                        .order-img02 {
                            order: 2 !important;
                        }
                    }
                    @media (max-width: 768px){
                        .click-vr {
                            height: 310px !important;
                        }
                        .order-text01 {
                            order: 2 !important;
                        }
                        .order-img01 {
                            order: 1 !important;
                        }
                        .order-text02 {
                            order: 2 !important;
                        }
                        .order-img02 {
                            order: 1 !important;
                        }
                    }
                `}
            </style>
            <Header />
            <div className="container-fluid w-100">
                <div className="row">
                    <div className="col-12 px-0">
                        <div className="image-gallery-wrapper">
                            <ImageGallery
                                items={heroBanner}
                                autoPlay={true}
                                showThumbnails={false}
                                showFullscreenButton={false}
                                showBullets={true}
                                showNav={false}
                                renderItem={renderItem}
                                slideDuration={5000}
                            />
                        </div>
                    </div>
                </div>
                <div className="info-container">
                    <div className="info-data bg-sty02">
                        <ScrollAnimation animateIn='fadeInUp'
                        initiallyVisible={true}>
                        <div className="mx-10 mt-4">
                            <HeadingSection title={t('business_condo_title')} subTitle={t('business_condo_subtitle')}
                                    position={'center'}
                                    customize={{
                                        paddingBottom: '1rem',
                                        paddingTop: '2rem'
                                    }}
                                    classCss="pdt-hd pdb-hd"
                                    subTitleDetail="d-block"
                            />
                        </div>
                        <div className="row text-center d-flex justify-content-center align-items-center w-100 m-0">
                                <div className="col-12 col-md-6 pt-3 mb-5">
                                    <font className="font-size02 colorBlack01 fontLight"><div dangerouslySetInnerHTML={{ __html: txtDesc}}></div></font>
                                </div>
                        </div>
                        </ScrollAnimation>
                    </div>
                    <div className="info-data">
                        <ScrollAnimation animateIn='fadeInUp'
                        initiallyVisible={true}>
                        <div className='button-container'>
                            <Row className='title-btn p-0 w-100 mx-0'>
                                <Col xs={6} className='d-flex justify-content-end'>
                                    <Button 
                                        className={`btn-bidInfo btn-bidding01 fontMed font-size05 ${activeTab === 'forRent' ? 'active' : ''}`}
                                        onClick={() => handleTabClick('forRent')}>
                                        {/* CONDO<br/>FOR RENT */}
                                        {t('business_condo_rent_title')}<br/>
                                        {t('business_condo_rent_subtitle')}
                                    </Button>
                                </Col>
                                <Col xs={6} className='d-flex justify-content-start'>
                                    <Button 
                                        className={`btn-bidWinn btn-bidding01 fontMed font-size05 ${activeTab === 'forSale' ? 'active' : ''}`}
                                        onClick={() => handleTabClick('forSale')}>
                                        {/* CONDO<br/>FOR SALE */}
                                        {t('business_condo_sale_title')}<br/>
                                        {t('business_condo_sale_subtitle')}
                                    </Button>
                                </Col>
                            </Row>
                        </div>
                        </ScrollAnimation>
                        <ScrollAnimation animateIn='fadeInUp'
                            animatePreScroll={false}>
                        <div className="mx-10 mt-4">
                                <HeadingSection title={t('business_condo_rent_title')} subTitle={activeTab=='forRent'? t('business_condo_rent_subtitle'):t('business_condo_sale_subtitle')}
                                    position={'center'}
                                    customize={{
                                        paddingBottom: '1rem',
                                        paddingTop: '2rem'
                                    }}
                                    classCss="pdt-hd"
                                    subTitleDetail="d-block"
                            />
                                <div className='w-100 d-flex justify-content-center'><span className="bd-bottom01"></span></div>
                        </div>
                        <div className="row text-center d-flex justify-content-center align-items-center w-100 m-0">
                                <div className="col-12 col-md-6 pt-3">
                                    <font className="font-size02 colorBlack01 fontReg">{titleDesc}</font>
                                </div>
                        </div>
                        <div className="row text-center d-flex justify-content-center align-items-center w-100 m-0">
                                <div className="col-12 col-md-6 pt-3">
                                    <font className="font-size02 colorBlack01 fontLight"><div dangerouslySetInnerHTML={{ __html: txtDescDetail}}></div></font>
                                </div>
                        </div>
                        </ScrollAnimation>
                        {aspVirtualLink && <ScrollAnimation animateIn='fadeInUp'
                            animatePreScroll={false}>
                        <div className="row text-center d-flex justify-content-center align-items-center w-100 m-0">
                                <div className="col-12 pt-3 px-0">
                                    <h1 className='mt-5 mb-3 fontBold font-size06'>
                                        {t('business_condo_virtual')}
                                    </h1>
                                </div>
                                <div className="col-12 pt-3 px-0 position-relative click-vr" onClick={handleClickVr}>
                                    {vrMode ? null : <div className='box-bg'></div>}
                                    {vrMode ? null : <img src="assets/images/cursor-custom.png" className='cursor-click' alt=''/>}
                                    <iframe src={aspVirtualLink} title="Iframe Example" className='w-100 h-800'></iframe>
                                </div>
                        </div>
                        </ScrollAnimation>}
                        <ScrollAnimation animateIn='fadeInUp'
                            animatePreScroll={false}>
                        <div className="row w-100 mx-0 mt-7 mb-0 bg-sty01">
                                <div className="col-12 col-md-6 ps-0 pe-0 order-img01">
                                    <CardSlider4
                                        data={imgRoom1}
                                    />
                                </div>
                                <div className="col-12 col-md-6 d-flex justify-content-center align-items-center flex-column order-text01 py-3 py-md-0">
                                    <ScrollAnimation animateIn="fadeInRight" delay={100} className='w-75'>
                                    <div className="text-left">
                                        <div className="row w-100 m-0">
                                            <div className="col-12">
                                                <font className="font-size04 colorBlack01 fontBold">{titleRoom1}</font>
                                            </div>
                                        </div>
                                        <div className="row w-100 m-0">
                                            <div className="col-12 mt-3">
                                                <div className="text-left">
                                                    <font className="font-size02 colorBlack01 fontLight"><div dangerouslySetInnerHTML={{ __html: txtRoom1}}></div></font>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    </ScrollAnimation>
                                </div>
                        </div>
                        </ScrollAnimation>
                        <ScrollAnimation animateIn='fadeInUp'
                            animatePreScroll={false}>
                        <div className="row w-100 m-0 bg-sty01">
                                <div className="col-12 col-md-6 d-flex justify-content-center align-items-center flex-column order-text02 py-3 py-md-0">
                                    <ScrollAnimation animateIn="fadeInLeft" delay={100} className='w-75'>
                                    <div className="text-left">
                                        <div className="row w-100 m-0">
                                            <div className="col-12">
                                                <font className="font-size04 colorBlack01 fontBold">{titleRoom2}</font>
                                            </div>
                                        </div>
                                        <div className="row w-100 m-0">
                                            <div className="col-12 mt-3">
                                                <div className="text-left">
                                                    <font className="font-size02 colorBlack01 fontLight"><div dangerouslySetInnerHTML={{ __html: txtRoom2}}></div></font>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    </ScrollAnimation>
                                </div>
                                {/* TODO hotfix overflow image   */}
                                <div className="col-12 col-md-6 p-0  order-img02" style={{
                                    zIndex: 1,
                                    overflow:'hidden',
                                    height: '500px !important',
                                    }}>
                                    <CardSlider4
                                        data={imgRoom2}
                                    />
                                </div>
                        </div>
                        </ScrollAnimation>
                        <ScrollAnimation animateIn='fadeInUp'
                            animatePreScroll={false}>
                        <div className="row w-100 m-0 bg-sty01">
                                <div className="col-12 col-md-6 ps-0 pe-0 order-img01">
                                    <CardSlider4
                                        data={imgRoom3}
                                    />
                                </div>
                                <div className="col-12 col-md-6 d-flex justify-content-center align-items-center flex-column order-text01 py-3 py-md-0">
                                    <ScrollAnimation animateIn="fadeInRight" delay={100} className='w-75'>
                                    <div className="text-left">
                                        <div className="row w-100 m-0">
                                            <div className="col-12">
                                                <font className="font-size04 colorBlack01 fontBold">{titleRoom3}</font>
                                            </div>
                                        </div>
                                        <div className="row w-100 m-0">
                                            <div className="col-12 mt-3">
                                                <div className="text-left">
                                                    <font className="font-size02 colorBlack01 fontLight"><div dangerouslySetInnerHTML={{ __html: txtRoom3}}></div></font>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    </ScrollAnimation>
                                </div>
                        </div>
                        </ScrollAnimation>
                        <ScrollAnimation animateIn='fadeInUp'
                            animatePreScroll={false}>
                        <div className="mx-10 mt-4">
                                <HeadingSection title={t('business_condo_spec_title')} subTitle={t('business_condo_spec_subtitle')}
                                    position={'center'}
                                    customize={{
                                        paddingBottom: '1rem',
                                        paddingTop: '2rem'
                                    }}
                                    classCss="pdt-hd pdb-hd"
                                    subTitleDetail="d-block"
                                />
                        </div>
                        <div className="row w-100 mx-0 mt-6 bg-sty01">
                                <div className="col-12 col-md-6 p-0 order-img01">
                                    <img className="img-style-card01" src={imgSpec} alt=''/>
                                </div>
                                <div className="col-12 col-md-6 d-flex justify-content-center align-items-center flex-column order-text01 py-3 py-md-0">
                                    <ScrollAnimation animateIn="fadeInRight" delay={100} className='w-75'>
                                    <div className="text-left">
                                        <div className="row w-100 m-0">
                                            <div className="col-12">
                                                <font className="font-size04 colorBlack01 fontBold">{t('business_condo_spec')}</font>
                                            </div>
                                        </div>
                                        <div className="row w-100 m-0">
                                            <div className="col-12 mt-3">
                                                <div className="text-left">
                                                    <font className="font-size02 colorBlack01 fontLight"><div dangerouslySetInnerHTML={{ __html: detailSpec}}></div></font>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    </ScrollAnimation>
                                </div>
                        </div>
                        </ScrollAnimation>
                        <ScrollAnimation animateIn='fadeInUp'
                            animatePreScroll={false}>
                                {/* TODO HOTFIX IMAGE OVERLAP */}
                        <div className="row w-100 m-0 bg-sty01">
                                <div className="col-12 col-md-6 d-flex justify-content-center align-items-center flex-column order-text02 ">
                                    <ScrollAnimation animateIn="fadeInLeft" delay={100} className='w-75'>
                                    <div className="text-left">
                                        <div className="row w-100 m-0">
                                            <div className="col-12 mt-3 mt-lg-0">
                                                <font className="font-size04 colorBlack01 fontBold">{t('business_condo_fac')}</font>
                                            </div>
                                        </div>
                                        <div className="row w-100 m-0">
                                            <div className="col-12 mt-3">
                                                <div className="text-left">
                                                    <font className="font-size02 colorBlack01 fontLight"><div dangerouslySetInnerHTML={{ __html: detailFac}}></div></font>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    </ScrollAnimation>
                                </div>
                                <div className="col-12 col-md-6 order-img02 p-0" style={{aspectRatio:'2.129/1 !important'}}>
                                    <img className="img-style-card01" src={imgFac} alt=''/>
                                </div>
                        </div>
                        </ScrollAnimation>
                        <ScrollAnimation animateIn='fadeInUp'
                            animatePreScroll={false}>
                        <div className="mx-10 mt-4">
                                <HeadingSection title={t('business_condo_gallery_head')} subTitle={t('business_condo_gallery_sub')}
                                    position={'center'}
                                    customize={{
                                        paddingBottom: '1rem',
                                        paddingTop: '2rem'
                                    }}
                                    classCss="pdt-hd pdb-hd"
                                    subTitleDetail="d-block"
                                />
                            </div>
                            <div className="row p-0 w-100 m-0">
                                <div className="col-12 px-0">
                                    <div className="position-relative">
                                        <CardSlider5
                                            data={listGallery}
                                        />
                                    </div>
                                </div>
                        </div>
                        </ScrollAnimation>
                        <ScrollAnimation animateIn='fadeInUp'
                            animatePreScroll={false}>
                        <div className="mx-10 mt-4">
                                <HeadingSection title={t('business_condo_plan_title')} subTitle={t('business_condo_plan_subtitle')}
                                    position={'center'}
                                    customize={{
                                        paddingBottom: '1rem',
                                        paddingTop: '2rem'
                                    }}
                                    classCss="pdt-hd pdb-hd"
                                    subTitleDetail="d-block"
                                />
                            </div>
                            <div className="row p-0 w-100 m-0">
                                <div className="col-12 px-0">
                                    <div className="position-relative">
                                        <CardSlider7
                                            data={listPlan}
                                        />
                                    </div>
                                </div>
                        </div>
                        </ScrollAnimation>
                        <ScrollAnimation animateIn='fadeInUp'
                            animatePreScroll={false}>
                        <div className="bg-sty02">
                                <div className="mx-10 mt-4">
                                    <HeadingSection title={t('business_retail_promo_title')} subTitle={t('business_retail_promo_subtitle')}
                                        position={'center'}
                                        customize={{
                                            paddingBottom: '1rem',
                                            paddingTop: '2rem'
                                        }}
                                        classCss="pdt-hd pdb-hd"
                                        subTitleDetail="d-block"
                                    />
                                </div>
                                <div className="row p-0 w-100 m-0">
                                    <div className="col-12 px-0">
                                        <CardSlider2
                                            data={listDataPromo}
                                        />
                                    </div>
                                </div>
                            </div>
                            </ScrollAnimation>
                            <ScrollAnimation animateIn='fadeInUp'
                            animatePreScroll={false}>
                            <div className="row py-5 px-0 w-100 m-0">
                                <div className="col-12 col-md-6 px-0">
                                    <div className="d-flex justify-content-center align-items-center flex-column">
                                        <div className="text-left">
                                            <h2 className="colorBlack01 fontBold bd-bottom02 font-size14">{t('business_condo_enquiry')}</h2>
                                            <br/><font className="font-size02 colorBlack01 fontReg">Tel. 02-625-1869-74</font>
                                            <br/><font className="font-size02 colorBlack01 fontReg">Service/Assistance time to 9.00 AM – 17.30 PM</font>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 mt-3 mt-md-0">
                                    <div className="d-flex justify-content-center align-items-center align-items-md-start flex-column">
                                        <div className='box-form'>
                                            <div className='row '>
                                                <div className='col-12'>
                                                    <h3 className="colorBlack01 fontBold">{t('business_condo_form_title')}</h3>
                                                    <span className='bd-bottom03'></span>
                                                </div>
                                                <div className='col-12 mt-4'>
                                                    <input
                                                        type="text"
                                                        className="input-office fontLight font-size03"
                                                        id="Full_name"
                                                        placeholder={t('business_condo_form_name')}
                                                        value={dataAppForm.fullName}
                                                        onChange={(event) =>
                                                            setDataAppForm({
                                                                ...dataAppForm,
                                                                fullName: event.target.value,
                                                            })
                                                        }
                                                    />
                                                </div>
                                                <div className='col-12 mt-4'>
                                                    <input
                                                        type="text"
                                                        className="input-office fontLight font-size03"
                                                        id="Full_name"
                                                        placeholder={t('business_condo_form_email')}
                                                        value={dataAppForm.email}
                                                        onChange={(event) =>
                                                            setDataAppForm({
                                                                ...dataAppForm,
                                                                email: event.target.value,
                                                            })
                                                        }
                                                    />
                                                </div>
                                                <div className='col-12 mt-4'>
                                                    <input
                                                        type="text"
                                                        className="input-office fontLight font-size03"
                                                        id="Full_name"
                                                        placeholder={t('business_condo_form_tel')}
                                                        value={dataAppForm.tel}
                                                        onChange={(event) =>
                                                            setDataAppForm({
                                                                ...dataAppForm,
                                                                tel: event.target.value,
                                                            })
                                                        }
                                                    />
                                                </div>
                                                <div className='col-12 mt-4'>
                                                    <Link as={Link} onClick={() => submitData()} className='text-decoration-none text-dark fw-bold mt-2'>
                                                        <div className="btn-bidding">
                                                            <div className='d-flex align-items-center'>
                                                            <div className='fontReg font-size02 mx-3'>
                                                                {t('business_condo_form_submit')}
                                                            </div>
                                                            <div className='mx-2'>
                                                                <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M12.9678 25.9199C20.1254 25.9199 25.9278 20.1176 25.9278 12.96C25.9278 5.80235 20.1254 0 12.9678 0C5.81016 0 0.0078125 5.80235 0.0078125 12.96C0.0078125 20.1176 5.81016 25.9199 12.9678 25.9199Z" fill="white"/>
                                                                <path d="M12.3164 9.01953L16.2564 12.9595L12.3164 16.8994" stroke="#3EA33A" strokeLinecap="round" strokeLinejoin="round"/>
                                                                </svg>
                                                            </div>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </ScrollAnimation>
                    </div>
                </div>
            </div>
            <div>
                <ScrollToTopButton />
            </div>
            <Footer />
        </div>
    )
}

export default Condominium;