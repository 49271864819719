import React, { useEffect, useState, useContext } from 'react';
import Header from '../../template/Header';
import './PromoPrivilege.css';
import HeadingSection from "../../components/headingSection/headingSection";
import { Button, Col, Row } from "react-bootstrap";
import LandingContent from "../../components/landingContent/landingContent";
import { useMediaQueryHook } from "../../hooks/mediaQueryHook";
import "react-image-gallery/styles/css/image-gallery.css";
import Footer from "../../components/footer/footer";
import { getDataPromoPrivi } from '../../api/promo_privi_api';
import { ChangeLanguageContext } from '../../contexts/changeLanguageContext';
import { Link, useNavigate } from 'react-router-dom';
import {formatDate, formatDateTH} from "../../utils/formatDate";
import ImageGallery from "react-image-gallery";
import FacebookShare from '../ShareSocial/FacebookShare';
import TwitterShare from '../ShareSocial/TwitterShare';
import LineShare from '../ShareSocial/LineShare';
import LinkCopy from '../ShareSocial/LinkCopy';
import ScrollAnimation from 'react-animate-on-scroll';
import "animate.css/animate.compat.css"
import { useTranslation } from 'react-i18next';
import ScrollToTopButton from '../../components/scrollTopButton/ScrollTopButton';
import { isVideoFile } from '../../utils/functions';

function PromoPrivilege() {
    const { viewportSize } = useMediaQueryHook();
    const notContentLeftInMobile = viewportSize?.width <= 1024 ? false : true;
    const { lang } = useContext(ChangeLanguageContext);
    const { t } = useTranslation();
    const [dataId, setDataId] = useState(null);
    const [type, setType] = useState('');
    const [activeTab, setActiveTab] = useState('promo');
    const firstBanner = [{
        original: "https://dev-asp.verkoffice.com/assets/images/banner-promo-priv.png",
        thumbnail: "https://picsum.photos/id/1018/250/150/",
        description1: "PROMOTION & PRIVILAGE",
        description2: "AS THE PROMINENT",
        description3: "BUSINESS ADDRESS",
        isVideo: "",
    }]
    const [heroBanner, setHeroBanner] = useState(firstBanner);
    
    const [listDatas, setListDatas] = useState([
        {
            _id: '',
            imgItem: 'assets/images/offices/Group20.png',
            title: '',
            txtDiscount: '20',
            discountDate: '13 Jun 24',
            endDate: '17 Jun 24',
            publishDate: '19 Jun 24',
            txtDetail: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum has been the industry's standard dummy text ever since",
            note: '*According to the specified conditions',
            shareFB: '',
            shareX: '',
            shareXtext: 'All Season',
            shareLine: '',
            shareLineText: 'All Season',
            link: '',
            rank:'',
            coverImgs: ''
        },
        {
            _id: '',
            imgItem: 'assets/images/offices/Group21.png',
            title: '',
            txtDiscount: '20',
            discountDate: '13 Jun 24',
            endDate: '17 Jun 24',
            publishDate: '19 Jun 24',
            txtDetail: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum has been the industry's standard dummy text ever since",
            note: '*According to the specified conditions',
            shareFB: '',
            shareX: '',
            shareXtext: 'All Season',
            shareLine: '',
            shareLineText: 'All Season',
            link: '',
            rank:'',
            coverImgs: ''
        },
        {
            _id: '',
            imgItem: 'assets/images/offices/Group22.png',
            title: '',
            txtDiscount: '20',
            discountDate: '13 Jun 24',
            endDate: '17 Jun 24',
            publishDate: '19 Jun 24',
            txtDetail: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum has been the industry's standard dummy text ever since",
            note: '*According to the specified conditions',
            shareFB: '',
            shareX: '',
            shareXtext: 'All Season',
            shareLine: '',
            shareLineText: 'All Season',
            link: '',
            rank:'',
            coverImgs: ''
        },
        {
            _id: '',
            imgItem: 'assets/images/offices/Group23.png',
            title: '',
            txtDiscount: '20',
            discountDate: '13 Jun 24',
            endDate: '17 Jun 24',
            publishDate: '19 Jun 24',
            txtDetail: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum has been the industry's standard dummy text ever since",
            note: '*According to the specified conditions',
            shareFB: '',
            shareX: '',
            shareXtext: 'All Season',
            shareLine: '',
            shareLineText: 'All Season',
            link: '',
            rank:'',
            coverImgs: ''
        },
    ]);
    
    const renderItem = (item) => {
        return (
          <div className='image-gallery-item pd-bn'>
            {item.isVideo ? (
              <div className="video-wrapper">
                <video controls className='im-banner'>
                  <source src={item.original} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            ) : (
              <img src={item.original} alt={item.original} className='im-banner' />
            )}
            <div className='image-gallery-description'>
                <p className='mb-1 mb-md-2 fontBold font-size09 colorWhite'><div dangerouslySetInnerHTML={{ __html: item.description1}}></div></p>
                <p className='mb-2 fontLight font-size06 colorWhite'><div dangerouslySetInnerHTML={{ __html: item.description2}}></div></p>
                <p className='mb-1 fontLight font-size06 colorWhite'><div dangerouslySetInnerHTML={{ __html: item.description3}}></div></p>
                <p className='mb-2 bt-line-green'></p>
            </div>
          </div>
        );
    };    

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    const [tableParam, setTableParam] = useState({
      page_number:1,
      data_per_page:5,
    }); //กำหนด column เริ่มต้นในการ sort
    const [totalNumber, setTotalNumber] = useState(0)
    useEffect(()=>{
      fetchingData(lang=='en-US'?'en':lang)
    },[])
    
    
    useEffect(() => {
        fetchingData(lang=='en-US'?'en':lang);
    }, [lang])

    const handleSeeMore = async () => {
      try {
        let type = 'promo'
            if(activeTab=='promo'){
                type = 'promo'
            }
            else if(activeTab=='priv'){
                type = 'priv'
            }
            const infoDefault = {
                type:type
            }
        const param = {
          type:infoDefault.type,
          page_number:tableParam.page_number,
          data_per_page:totalNumber + 4,
        }
        let response = await getDataPromoPrivi(param);

        const infoList = response?.data?.data?.contentdata;
        if (infoList && infoList.length > 0) {
            let updatedInfoListDatas = infoList.map(detail => {
                return {
                    _id: detail._id,
                    imgItem: detail.imgs?.length>0?detail.imgs[0]:'',
                    title: detail.title,
                    txtDiscount: detail.discount,
                    discountDate: lang == 'th' ? formatDateTH(detail.discount_date) : formatDate(detail.discount_date),
                    endDate: lang == 'th' ? formatDateTH(detail.end_date) : formatDate(detail.end_date),
                    publishDate: lang == 'th' ? formatDateTH(detail.publish_date) : formatDate(detail.publish_date),
                    txtDetail: detail.description,
                    note: detail.note,
                    shareFB: detail.link,
                    shareLine: detail.link,
                    shareX: detail.link,
                    link: detail.link,
                    rank: detail.rank,
                    coverImgs: detail.cover_imgs?.length>0?detail.cover_imgs[0]:'',
                };
            });
            // updatedInfoListDatas.sort((a, b) => {
            // if (a.rank !== b.rank) {
            //     return a.rank - b.rank;
            // }
            // return new Date(b.discountDate) - new Date(a.discountDate);
            // });
            setListDatas(updatedInfoListDatas);
            if(response.data.data.meta.total < totalNumber + 4)
            {
                
            }
            else{
                setTotalNumber(totalNumber + 4)
            }
        }
      } catch (error) {
        console.log('found error', error);
      }
    };

    const navigateToDetail = (id, type) => {
        return `/promoPriviDetail?id=${id}&type=${type}`;
    };
    const fetchingData = async (language) => {
        try {
            let type = 'promo'
            if(activeTab=='promo'){
                type = 'promo'
            }
            else if(activeTab=='priv'){
                type = 'priv'
            }
                const infoDefault = {
                    type:type,
                    data_per_page: 5
            }
            setType(infoDefault.type);
            const result = await getDataPromoPrivi(infoDefault, language);
            const banners = result?.data?.data?.banner?.data.map((item) => ({
              original: item.data,
              thumbnail: item.data,
              description1: item.supertext,
              description2: item.description,
              description3: '',
              isVideo: isVideoFile(item.format),
            }));
            let bannerConcat = firstBanner.concat(banners);
            setHeroBanner(bannerConcat);
            setDataId(result?.data?.data?._id)
            const infoList = result?.data?.data?.contentdata;
            if (infoList && infoList.length > 0) {
              let updatedInfoListDatas = infoList.map(detail => {
                  return {
                        _id: detail._id,
                        imgItem: detail.imgs?.length>0?detail.imgs[0]:'',
                        title: detail.title,
                        txtDiscount: detail.discount,
                        discountDate: language == 'th' ? formatDateTH(detail.discount_date) : formatDate(detail.discount_date),
                        endDate: language == 'th' ? formatDateTH(detail.end_date) : formatDate(detail.end_date),
                        publishDate: language == 'th' ? formatDateTH(detail.publish_date) : formatDate(detail.publish_date),
                        txtDetail: detail.description,
                        shareLineText: detail?.shareLineText ,
                        shareXtext: detail?.shareXtext ,
                        note: detail.note,
                        shareFB: detail.link,
                        shareLine: detail.link,
                        shareX: detail.link,
                        link: detail.link,
                        rank: detail.rank,
                        coverImgs: detail.cover_imgs?.length>0?detail.cover_imgs[0]:'',
                  };
              });
            //   updatedInfoListDatas.sort((a, b) => {
            //     if (a.rank !== b.rank) {
            //       return a.rank - b.rank;
            //     }
            //     return new Date(b.discountDate) - new Date(a.discountDate);
            //   });
              setListDatas(updatedInfoListDatas);
              setTotalNumber(5)
          } else {
                // Handle case when detail_list is empty
                // You may want to set an empty array or handle it differently
                setListDatas([]);
            }
        } catch (error) {
            console.log('found error', error);
        }
    };

    useEffect(() => {
        
        let id = new URLSearchParams(window.location.search).get("id");
        let type = new URLSearchParams(window.location.search).get("type");
            if (id) {
                fetchingData(lang=='en-US'?'en':lang, id);
                setDataId(id);
                setType(type);
            }
      }, [lang])
  
      useEffect(() => {
        
          fetchingData(lang === 'en-US' ? 'en' : lang);
      }, [activeTab]);

    return (
        <div>
            <style>
                {`
                    ul.menu li button#business {
                    color: #3AF823 !important;
                    text-decoration: underline;
                    }
                    video.im-banner {
                        width: 100% !important;
                        height: 600px !important;
                    }
                    .bd-bottom01 {
                        border-bottom: 6px solid #3EA33A;
                        width: 9% !important;
                        position: relative;
                        display: block;
                    }
                    .detail {
                        height: auto !important;
                        margin-left: -155px !important;
                    }
                    @media (max-width: 1250px) {
                        .sty-promo1 {
                            padding: 20px !important;
                        }
                    }
                    @media (max-width: 1200px) {
                        .detail {
                            width: 100% !important;
                            margin-left: 0px !important;
                            min-height: 310px;
                        }
                    }
                    @media (max-width: 1150px) {
                        .sty-promo1 {
                            padding: 25px !important;
                        }
                    }
                `}
            </style>
            <Header />
            <div className="container-fluid w-100">
                <div className="row">
                    <div className="col-12 px-0">
                        <div className="image-gallery-wrapper">
                            <ImageGallery
                            items={heroBanner}
                            autoPlay={true}
                            showThumbnails={false}
                            showFullscreenButton={false}
                            showBullets={true}
                            showNav={false}
                            renderItem={renderItem}
                            slideDuration={5000}
                            />
                        </div>
                    </div>
                </div>
                <div className='button-container'>
                    <Row className='title-btn p-0 w-100 mx-0'>
                        <Col xs={6} className='d-flex justify-content-end'>
                            <Button 
                                className={`btn-bidInfo btn-bidding01 fontMed font-size05 ${activeTab === 'promo' ? 'active' : ''}`}
                                onClick={() => handleTabClick('promo')}>
                                {t('promo_priv_head')}
                            </Button>
                        </Col>
                        <Col xs={6} className='d-flex justify-content-start'>
                            <Button 
                                className={`btn-bidWinn btn-bidding01 fontMed font-size05 ${activeTab === 'priv' ? 'active' : ''}`}
                                onClick={() => handleTabClick('priv')}>
                                {t('promo_priv_subtitle')}
                            </Button>
                        </Col>
                    </Row>
                </div>
                <div className="info-container">
                    <div className="info-data">
                        <div className="mx-10 mt-4">
                            <HeadingSection title={activeTab=='promo'?t('promo_priv_head'):t('promo_priv_subtitle')}
                                position={'center'}
                                customize={{
                                    paddingBottom: '1rem',
                                    paddingTop: '2rem'
                                }}
                                classCss="pdt-hd"
                                subTitleDetail="d-block"
                            />
                            <div className='w-100 d-flex justify-content-center mt-46'><span className="bd-bottom01 mt-6"></span></div>
                        </div>
                    </div>
                </div>
                {
                     listDatas.length <= 0 ? (
                        <>
                        {<ScrollAnimation animateIn='fadeIn'
                            initiallyVisible={true}>
                            <div className='sty-col1 m-5 pb-5'>
                                <div className="col-12 col-xl-12">
                                    <div className='row justify-content-center align-items-center' >
                                        {/* <p className="fontReg font-size04 colorBlack01 mt-5 mb-3 text-center text-italic colorGray01">{t('news_news_empty_data')}</p> */}
                                        <p className="fontReg font-size04 colorBlack01 mt-5 mb-3 text-center text-italic colorGray01">{activeTab === 'promo' ? t('promo_promo_empty_data') : t('promo_priv_empty_data')}</p>
                                    </div>
                                </div>
                            </div>
                            </ScrollAnimation>}
                        </>
                     ) 
                     : (
                     <>
                          <ScrollAnimation animateIn='fadeIn'
                            initiallyVisible={true}>
                            <div className="sty-col1 my-5">
                                <div className="row justify-content-center align-items-center">
                                    <div className="col-12 col-xl-6">
                                        <img src={listDatas[0].coverImgs} className='img-style-card04' alt=''/>
                                    </div>
                                    <div className="col-12 col-xl-6 text-dark bg-light-white detail d-flex flex-column align-items-start p-4">
                                        <h2 className="fontBold"> <span className="font-size10 fontBold">{listDatas[0].txtDiscount} </span></h2>
                                        <p className="bd-bottom05"></p>
                                        <p className="fontBold font-size06 mb-2">{listDatas[0].title}</p>
                                        <p className="font-size02 colorBlack01 fontLight mb-0 mb-md-1 text-underline">{t('promo_priv_detail')}</p>
                                        <p className="font-size03 colorBlack01 fontLight mb-0 mb-md-3 multi-line-ellipsis02"><div dangerouslySetInnerHTML={{ __html: listDatas[0].txtDetail}}></div></p>
                                        {/* <p className="font-size08 colorBlack01 fontMed mb-1 mb-md-3">{listDatas[0].discountDate} - {listDatas[0].endDate}</p> */}
                                        {/* <p className="font-size08 colorBlack01 fontMed mb-1 mb-md-3">
                                            {listDatas[0].discountDate == listDatas[0].endDate
                                                ? listDatas[0].discountDate
                                                : `${listDatas[0].discountDate} - ${listDatas[0].endDate}`}
                                        </p> */}
                                        <p className="font-size08 colorBlack01 fontMed mb-1 mb-md-3">
                                            {`Publish Date: ${listDatas[0].publishDate}`}
                                        </p>
                                        <p className="font-size03 colorBlack01 fontLight mb-0 mb-md-3">{listDatas[0].note}</p>
                                        <div className="d-flex justify-content-start w-100">
                                            <div className="d-flex flex-column flex-sm-row justify-content-start justify-content-sm-between w-100">
                                                <div className='d-flex flex-row align-items-center'>
                                                    <font className="font-size02 fontMed colorBlack01 text-decoration-none">
                                                        {t('promo_priv_share')}
                                                    </font>
                                                    <div className='ms-3 wc-100'>
                                                        <FacebookShare url={listDatas[0].shareFB} />
                                                    </div>
                                                    <div className='ms-2 wc-100'>
                                                        <TwitterShare url={listDatas[0].shareX} text={listDatas[0].shareXtext} />
                                                    </div>
                                                    <div className='ms-2 wc-100'>
                                                        <LineShare url={listDatas[0].shareLine} text={listDatas[0].shareLineText} />
                                                    </div>
                                                    <div className='ms-2 wc-100'>
                                                        <LinkCopy url={listDatas[0].link}></LinkCopy>
                                                    </div>
                                                </div>
                                                <div className='d-flex align-items-end mt-3 mt-sm-0'>
                                                    <Link to={navigateToDetail(listDatas[0]._id, activeTab=='promo'?'promo':'priv')} className="fontReg readmore">{t('promo_priv_continue_reading')}{' > '}</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </ScrollAnimation>
                            <ScrollAnimation animateIn='fadeIn'
                            animatePreScroll={false}>
                            <div className="w-100 my-5">
                                <div className='row'>
                                {listDatas.slice(1).map((data, index) => (
                                    <div key={index} className={`col-12 col-xl-6 rank${data.rank}`}>
                                            {index%4 == 0 || index%4 == 1 ?
                                                <div className="row">
                                                    <div className='col-12 col-sm-6 p-0 order-img03'>
                                                        <div width={'100%'}>
                                                            <img src={data.imgItem} className='img-style-cv02' alt=''/>
                                                        </div>
                                                    </div>
                                                    <div className='col-12 col-sm-6 sty-promo1 order-text03'>
                                                        <div className='w-100'>
                                                            <h2 className="fontBold"> <span className="font-size10 fontBold">{data.txtDiscount} </span></h2>
                                                            <p className="bd-bottom04"></p>
                                                            <p className="fontBold font-size06">{data.title}</p>
                                                            <p className="font-size02 colorBlack01 fontLight mb-0 mb-md-1 multi-line-ellipsis02"><div dangerouslySetInnerHTML={{ __html: data.txtDetail}}></div></p>
                                                            {/* <p className="font-size08 colorBlack01 fontMed mb-1 mb-md-3">{data.discountDate} - {data.endDate}</p> */}
                                                            {/* <p className="font-size08 colorBlack01 fontMed mb-1 mb-md-3">
                                                                {data.discountDate == data.endDate
                                                                    ? data.discountDate
                                                                    : `${data.discountDate} - ${data.endDate}`}
                                                            </p> */}
                                                            <p className="font-size08 colorBlack01 fontMed mb-1 mb-md-3">
                                                                {`Publish Date: ${data.publishDate}`}
                                                            </p>
                                                            {/* <p className="font-size08 colorBlack01 fontMed mb-1 mb-md-3">
                                                                {`Publish Date: ${listDatas[0].publishDate}`}
                                                            </p> */}
                                                            <p className="font-size03 colorBlack01 fontLight mb-0 mb-md-1">{data.note}</p>
                                                            <font className="colorBlack font-size03 fontMed text-underline">
                                                                {t('promo_priv_share')}
                                                            </font>
                                                            <div className="d-flex justify-content-between flex-row align-items-start align-items-md-center w-100 mt-2">
                                                                <div className='d-flex flex-row align-items-center'>
                                                                    <div className='w-100'>
                                                                        <FacebookShare url={data.shareFB} />
                                                                    </div>
                                                                    <div className='ms-2 w-100'>
                                                                        <TwitterShare url={data.shareX} text={data.shareXtext} />
                                                                    </div>
                                                                    <div className='ms-2 w-100'>
                                                                        <LineShare url={data.shareLine} text={data.shareLineText} />
                                                                    </div>
                                                                    <div className='ms-2 w-100'>
                                                                        <LinkCopy url={data.link}></LinkCopy>
                                                                    </div>
                                                                </div>
                                                                <div className="mt-2 mt-lg-0">
                                                                    <Link key={index} to={navigateToDetail(data._id, type === 'promo' ? 'promo' : 'priv')} className='btn-see font-size02 fontMed'>
                                                                        {t('promo_priv_continue_seemore')}
                                                                    </Link>                                                
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                <div className="row">
                                                    <div className='col-12 col-sm-6 sty-promo1 order-text04'>
                                                        <div className='w-100'>
                                                            <h2 className="fontBold"> <span className="font-size10 fontBold">{data.txtDiscount} </span></h2>
                                                            <p className="bd-bottom04"></p>
                                                            <p className="fontBold font-size06">{data.title}</p>
                                                            <p className="font-size02 colorBlack01 fontLight mb-0 mb-md-1 multi-line-ellipsis02"><div dangerouslySetInnerHTML={{ __html: data.txtDetail}}></div></p>
                                                            {/* <p className="font-size11 colorBlack01 fontMed mb-1 mb-md-3">{data.discountDate} - {data.endDate}</p> */}
                                                            {/* <p className="font-size08 colorBlack01 fontMed mb-1 mb-md-3">
                                                                {data.discountDate == data.endDate
                                                                    ? data.discountDate
                                                                    : `${data.discountDate} - ${data.endDate}`}
                                                            </p> */}
                                                            <p className="font-size08 colorBlack01 fontMed mb-1 mb-md-3">
                                                                {`Publish Date: ${data.publishDate}`}
                                                            </p>
                                                            {/* <p className="font-size08 colorBlack01 fontMed mb-1 mb-md-3">
                                                                {`Publish Date: ${listDatas[0].publishDate}`}
                                                            </p> */}
                                                            <p className="font-size03 colorBlack01 fontLight mb-0 mb-md-1">{data.note}</p>
                                                            <font className="colorBlack font-size03 fontMed text-underline">
                                                                {t('promo_priv_share')}
                                                            </font>
                                                            <div className="d-flex justify-content-between flex-row align-items-start align-items-md-center w-100 mt-2">
                                                                <div className='d-flex flex-row align-items-center'>
                                                                    <div className='w-100'>
                                                                        <FacebookShare url={data.shareFB} />
                                                                    </div>
                                                                    <div className='ms-2 w-100'>
                                                                        <TwitterShare url={data.shareX} text={data.shareXtext} />
                                                                    </div>
                                                                    <div className='ms-2 w-100'>
                                                                        <LineShare url={data.shareLine} text={data.shareLineText} />
                                                                    </div>
                                                                    <div className='ms-2 w-100'>
                                                                        <LinkCopy url={data.link}></LinkCopy>
                                                                    </div>
                                                                </div>
                                                                <div className="mt-2 mt-md-0">
                                                                    <Link key={index} to={navigateToDetail(data._id, type === 'promo' ? 'promo' : 'priv')} className='btn-see font-size02 fontMed'>
                                                                        {t('promo_priv_continue_seemore')}
                                                                    </Link>                                                                      
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-12 col-sm-6 p-0 order-img04'>
                                                        <div width={'100%'}>
                                                            <img src={data.imgItem} className='img-style-cv02' alt=''/>
                                                        </div>
                                                    </div>
                                                </div>
                                            }                            
                                        </div>
                                    ))}
                                </div>
                            </div>
                            </ScrollAnimation>
                            <Row className="my-4">
                                <Col xs={12} className="d-flex justify-content-center align-items-center">
                                    <Button className="btn-apply font-size03 fontBold" onClick={() => handleSeeMore()}>
                                    {activeTab == 'promo' ? t('promo_priv_continue_seemore_promo') : t('promo_priv_continue_seemore_priv')}&nbsp;
                                    <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M13.7491 26.8896C20.9067 26.8896 26.709 21.0873 26.709 13.9297C26.709 6.77208 20.9067 0.969727 13.7491 0.969727C6.59147 0.969727 0.789062 6.77208 0.789062 13.9297C0.789062 21.0873 6.59147 26.8896 13.7491 26.8896Z" fill="black"/>
                                        <path d="M13.1094 9.99023L17.0494 13.9302L13.1094 17.8704" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                    </Button>
                                </Col>
                            </Row>
                     </>
                     )
                }
              
            </div>
            <div>
                <ScrollToTopButton />
            </div>
            <Footer />
        </div>
    )
}

export default PromoPrivilege;