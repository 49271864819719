import React from 'react'
import { useEffect } from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate
} from "react-router-dom";
import Home from './pages/Home/Home'
import './App.css';
import "animate.css/animate.compat.css"
import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import enTranslation from './locales/en.json';
import thTranslation from './locales/th.json';
import { ChangeLanguageContextProvider } from "./contexts/changeLanguageContext"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AboutUs from './pages/AboutUs/AboutUs';
import Careers from './pages/Careers/Careers';
import ApplicationForm from './pages/Careers/ApplicationForm';
import Bidding from './pages/OpenTendering/Bidding';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import BiddingDetail from './pages/OpenTendering/BiddingDetail';
import RetailCenter from './pages/Business/RetailCenter';
import Condominium from './pages/Business/Condominium';
import ContactUs from './pages/ContactUs/ContactUs';
import ASPCus from './pages/OurServices/ASPCus';
import ASPClub from './pages/OurServices/ASPClub';
import Carpark from './pages/OurServices/Carpark';
import ShuttleBus from './pages/OurServices/ShuttleBus';
import NewsEvent from './pages/NewsEvent/NewsEvent';
import PromoPrivilege from './pages/PromoPrivilege/PromoPrivilege';
import PromoPriviDetail from './pages/PromoPrivilege/PromoPriviDetail';
import Offices from './pages/Business/Offices';
import OurService from './pages/OurServices/OurService';
import NewEventDetail from './pages/NewsEvent/NewEventDetail';
import { ConfigContextProvider } from './contexts/configContext';
import ReactGA from 'react-ga4';
 
function App() {
  ReactGA.initialize('G-66HJXF950G');
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);
  i18n
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
      resources: {
        en: {
          translation: enTranslation,
        },
        th: {
          translation: thTranslation,
        },
      },
      fallbackLng: 'en',
      debug: false
    });

  return (
    <div className="wrapper">
      <ChangeLanguageContextProvider>
        <ConfigContextProvider>
          <BrowserRouter basename={"/"}>
            <Routes>
              <Route path="/" element={<Navigate to="/home" replace={true} />} />
              <Route path="/home" element={<Home />} />
              <Route path="/aboutUs" element={<AboutUs/>}/>
              <Route path="/careers" element={<Careers/>}/>
              <Route path="/applicationForm" element={<ApplicationForm/>}/>
              <Route path="/bidding" element={<Bidding/>}/>
              <Route path="/biddingDetail" element={<BiddingDetail/>}/>
              <Route path="/offices" element={<Offices/>}/>
              <Route path="/retailCenter" element={<RetailCenter/>}/>
              <Route path="/condominium" element={<Condominium/>}/>
              <Route path="/contactUs" element={<ContactUs/>}/>
              <Route path="/ourServices" element={<OurService/>}/>
              <Route path="/aspClub" element={<ASPClub/>}/>
              <Route path="/carPark" element={<Carpark/>}/>
              <Route path="/shuttleBus" element={<ShuttleBus/>}/>
              <Route path="/newsEvent" element={<NewsEvent/>}/>
              <Route path="/newsEventDetail" element={<NewEventDetail/>}/>
              <Route path="/promoPrivilege" element={<PromoPrivilege/>}/>
              <Route path="/promoPriviDetail" element={<PromoPriviDetail/>}/>
            </Routes>
          </BrowserRouter>
        </ConfigContextProvider>
      </ChangeLanguageContextProvider>
    </div>
  );
}

export default App;
// library.add(fab)