import React, { useEffect, useState, useContext } from 'react';
import Header from '../../template/Header';
import './NewsEvent.css';
import HeadingSection from "../../components/headingSection/headingSection";
import { Col, Row } from "react-bootstrap";
import { useMediaQueryHook } from "../../hooks/mediaQueryHook";
import "react-image-gallery/styles/css/image-gallery.css";
import Footer from "../../components/footer/footer";
import { getNewsEventByID } from '../../api/news_event_api';
import { ChangeLanguageContext } from '../../contexts/changeLanguageContext';
import {formatDate, formatDateTH} from "../../utils/formatDate";
import CardSlider6 from "../../components/cardSlide/Slick6"
import FacebookShare from '../ShareSocial/FacebookShare';
import TwitterShare from '../ShareSocial/TwitterShare';
import LineShare from '../ShareSocial/LineShare';
import LinkCopy from '../ShareSocial/LinkCopy';
import { useTranslation } from "react-i18next"
import ScrollAnimation from 'react-animate-on-scroll';
import "animate.css/animate.compat.css"
import ScrollToTopButton from '../../components/scrollTopButton/ScrollTopButton';

function NewEventDetail() {
    const { viewportSize } = useMediaQueryHook();
    const notContentLeftInMobile = viewportSize?.width <= 1024 ? false : true;
    const { lang } = useContext(ChangeLanguageContext);
    const { t } = useTranslation();
    const [type, setType] = useState('');
    const [imgDetail, setImgDetail] = useState('');
    const [titleDetail, setTitleDetail] = useState('');
    const [txtDetail, setTxtDetail] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, steEndDate] = useState('');
    const [share, setShare] = useState('');
    const [shareXText, setShareXText] = useState('All Season');
    const [shareLineText, setShareLineText] = useState('All Season');
    const [dataId, setDataId] = useState(null);
    const [headBanner, setHeadBanner] = useState({
        original: "https://dev-asp.verkoffice.com/assets/images/banner-promo-priv.png",
        thumbnail: "https://picsum.photos/id/1018/250/150/",
        description1: "NEWS & EVENT",
        isVideo: "image",
    });

    const [listDatasSlider1, setListDatasSlider1] = useState([
        {
            images: [
                '/assets/images/retail/img1.png',
                '/assets/images/retail/img1.png',
                '/assets/images/retail/img1.png',
            ],
            txtDesc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to",
        },
        {
            images: [
                '/assets/images/retail/img1.png',
                '/assets/images/retail/img1.png',
                '/assets/images/retail/img1.png',
            ],
            txtDesc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to",
        },
        {
            images: [
                '/assets/images/retail/img1.png',
                '/assets/images/retail/img1.png',
                '/assets/images/retail/img1.png',
            ],
            txtDesc: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        }
    ]);

    const fetchingData = async (language, id) => {
        try {
            const result = await getNewsEventByID(id, {}, language);
            const bannerData = result?.data?.data?.banner?.data;
            if (bannerData) {
                const banners = {
                    original: bannerData.data,
                    thumbnail: bannerData.data,
                    isVideo: bannerData.format || "image",
                };
                setHeadBanner(banners);
            } else {
                console.error('Banner data is undefined');
            }

            if (result.data.status == 200||result.data.status == "success") {
                const data = result?.data?.data?.contentdata; 
                setDataId(data._id);
                setImgDetail(data.col1_imgs[0]);
                setTitleDetail(data.col1_title);
                setTxtDetail(data.col1_description);
                setShare(data.link);
                setShareLineText(data?.shareLineText)
                setShareXText(data?.shareXtext)
    
                const publishDate = data.publish_date;
                const formattedDate1 = language == 'th' ? formatDateTH(publishDate) : formatDate(publishDate);
                setStartDate(formattedDate1);

                const endDateDetail = data.end_date;
                const formattedDate2 = language == 'th' ? formatDateTH(endDateDetail) : formatDate(endDateDetail);
                steEndDate(formattedDate2);

                if (data) {
                    let updatedInfoListDatas = []
                    updatedInfoListDatas.push({
                        images: data.col1_imgs?.length>0?data.col1_imgs:[],
                        txtDesc: data.col1_description,
                    })
                    updatedInfoListDatas.push({
                        images: data.col2_imgs?.length>0?data.col2_imgs:[],
                        txtDesc: data.col2_description,
                    })
                    updatedInfoListDatas.push({
                        images: data.col3_imgs?.length>0?data.col3_imgs:[],
                        txtDesc: data.col3_description,
                    })
                    setListDatasSlider1(updatedInfoListDatas);
                } else {
                    // Handle case when detail_list is empty
                    // You may want to set an empty array or handle it differently
                    setListDatasSlider1([]);
                }
            } else {
                console.error("Data is missing in the result.");
            }
        } catch (error) {
            console.log('found error', error);
        }
    };

    useEffect(() => {
        
        let id = new URLSearchParams(window.location.search).get("id");
        let type = new URLSearchParams(window.location.search).get("type");
            if (id) {
                fetchingData(lang=='en-US'?'en':lang, id);
                setDataId(id);
                setType(type);
            }
    }, [lang])

  return (
    <>
        <style>
            {`
                ul.menu li a#news_event {
                    color: #3AF823;
                    text-decoration: underline;
                }
                .bd-bottom01 {
                    border-bottom: 6px solid #3EA33A;
                    width: 9% !important;
                    position: relative;
                    display: block;
                }
                .detail {
                    max-height: 495px !important;
                    margin-left: -155px !important;
                    width: 117% !important;
                }
                @media (max-width: 1200px) {
                    .detail {
                        width: 100% !important;
                        margin-left: 0px !important;
                        min-height: 310px;
                    }
                }
                @media screen and (max-width: 768px) {
                    .box-arr01 {
                        transform: translate(20%, -115%);
                    }
                    .box-arr02 {
                        transform: translate(-20%, -100%);
                    }
                    .slick-slide {
                        padding: 0 0px;
                    }
                }
            `}
        </style>
        <Header />
        <div className="container-fluid w-100">
            <div className="row">
                <div className="col-12 px-0">
                    <div className="image-gallery-wrapper">
                        <div className="position-relative">
                            <img src={headBanner.original} alt="Head Banner" className="banner-title" />
                            <div className="txt-banner">
                                <p className="fontBold font-size10 colorWhite mb-0">{t('news_event')}</p>
                                <p className="bd-bottom-banner"></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="info-container">
                <div className="info-data">
                    <div className="mx-10 mt-4">
                        <HeadingSection
                                title={type === 'news' ? t('news') : t('event')}
                                position='center'
                                customize={{
                                    paddingBottom: '1rem',
                                    paddingTop: '2rem'
                                }}
                                classCss="pdt-hd"
                                subTitleDetail="d-block"
                        />
                        <div className='w-100 d-flex justify-content-center mt-46'><span className="bd-bottom01 mt-6"></span></div>
                    </div>
                </div>
            </div>
            <Row className='mt-4'>
                <Col xs={12}>
                    <div className='px-10'>
                        <img src={imgDetail} alt='' className='img-style'/>
                    </div>
                </Col>
            </Row>
            <ScrollAnimation animateIn='fadeIn'
            initiallyVisible={true}>
            <Row className='mt-4'>
                <Col xs={12}>
                    <div className='px-20'>
                        <font className="font-size04 colorBlack01 fontBold">{titleDetail}</font>
                    </div>
                </Col>
            </Row>
            </ScrollAnimation>
            <ScrollAnimation animateIn='fadeIn'
            initiallyVisible={true}>
            <Row className='my-3'>
                <Col xs={12}>
                    <div className='px-20'>
                        <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12.883 25.3391C6.07297 25.3391 0.542969 19.7991 0.542969 12.9991C0.542969 6.19915 6.08296 0.65918 12.883 0.65918C19.683 0.65918 25.223 6.19915 25.223 12.9991C25.223 19.7991 19.683 25.3391 12.883 25.3391ZM12.883 2.76917C7.24297 2.76917 2.65295 7.35915 2.65295 12.9991C2.65295 18.6391 7.24297 23.2291 12.883 23.2291C18.523 23.2291 23.103 18.6391 23.103 12.9991C23.103 7.35915 18.513 2.76917 12.883 2.76917Z" fill="black"/>
                            <path d="M12.8803 14.0498C12.3003 14.0498 11.8203 13.5797 11.8203 12.9897V5.35974C11.8203 4.77974 12.2903 4.2998 12.8803 4.2998C13.4703 4.2998 13.9403 4.76974 13.9403 5.35974V12.9897C13.9403 13.5697 13.4703 14.0498 12.8803 14.0498Z" fill="black"/>
                            <path d="M12.8803 3.45935C12.3003 3.45935 11.8203 2.98941 11.8203 2.39941V1.70935C11.8203 1.12935 12.2903 0.649414 12.8803 0.649414C13.4703 0.649414 13.9403 1.11935 13.9403 1.70935V2.39941C13.9403 2.97941 13.4703 3.45935 12.8803 3.45935Z" fill="black"/>
                            <path d="M12.8803 25.3392C12.3003 25.3392 11.8203 24.8693 11.8203 24.2793V23.5892C11.8203 23.0092 12.2903 22.5293 12.8803 22.5293C13.4703 22.5293 13.9403 22.9992 13.9403 23.5892V24.2793C13.9403 24.8593 13.4703 25.3392 12.8803 25.3392Z" fill="black"/>
                            <path d="M24.1602 14.0497H23.4702C22.8902 14.0497 22.4102 13.5796 22.4102 12.9896C22.4102 12.3996 22.8802 11.9297 23.4702 11.9297H24.1602C24.7402 11.9297 25.2202 12.3996 25.2202 12.9896C25.2202 13.5796 24.7502 14.0497 24.1602 14.0497Z" fill="black"/>
                            <path d="M2.28125 14.0497H1.59125C1.01125 14.0497 0.53125 13.5796 0.53125 12.9896C0.53125 12.3996 1.00125 11.9297 1.59125 11.9297H2.28125C2.86125 11.9297 3.34125 12.3996 3.34125 12.9896C3.34125 13.5796 2.87125 14.0497 2.28125 14.0497Z" fill="black"/>
                            <path d="M17.694 5.1887C17.604 5.1887 17.514 5.16864 17.434 5.11864C17.184 4.96864 17.094 4.64867 17.234 4.39867L17.544 3.85863C17.694 3.60863 18.014 3.51869 18.264 3.66869C18.514 3.81869 18.604 4.13866 18.454 4.38866L18.144 4.9287C18.044 5.0987 17.874 5.19859 17.684 5.19859L17.694 5.1887Z" fill="black"/>
                            <path d="M7.76434 22.399C7.67434 22.399 7.58433 22.3789 7.50433 22.3289C7.25433 22.1789 7.16432 21.8589 7.30432 21.6089L7.61431 21.069C7.76431 20.809 8.08431 20.7289 8.33431 20.8689C8.58431 21.0189 8.67432 21.3389 8.52432 21.5889L8.21432 22.1289C8.11432 22.2989 7.94433 22.399 7.75433 22.399H7.76434Z" fill="black"/>
                            <path d="M21.7535 18.6483C21.6635 18.6483 21.5735 18.6282 21.4935 18.5782L20.9535 18.2683C20.7035 18.1183 20.6135 17.7983 20.7535 17.5483C20.9035 17.2983 21.2235 17.2082 21.4735 17.3482L22.0135 17.6583C22.2635 17.8083 22.3535 18.1283 22.2035 18.3783C22.1035 18.5483 21.9335 18.6483 21.7435 18.6483H21.7535Z" fill="black"/>
                            <path d="M4.55432 8.70875C4.46432 8.70875 4.37434 8.6888 4.29434 8.6388L3.75433 8.32874C3.50433 8.17874 3.41432 7.85877 3.55432 7.60877C3.69432 7.35877 4.02432 7.26882 4.27432 7.40882L4.81433 7.71876C5.06433 7.86876 5.15434 8.18885 5.01434 8.43885C4.91434 8.60885 4.74432 8.70875 4.55432 8.70875Z" fill="black"/>
                            <path d="M8.07256 5.18895C7.89256 5.18895 7.71254 5.08893 7.61254 4.91893L7.30254 4.37889C7.16254 4.12889 7.24255 3.79892 7.50255 3.65892C7.75255 3.50892 8.07255 3.59886 8.22255 3.84886L8.53255 4.3889C8.68255 4.6389 8.59255 4.96887 8.34255 5.10887C8.26255 5.15887 8.17254 5.17894 8.08254 5.17894L8.07256 5.18895Z" fill="black"/>
                            <path d="M18.011 22.3986C17.831 22.3986 17.6509 22.3086 17.5509 22.1386L17.2409 21.5986C17.0909 21.3486 17.1809 21.0186 17.4309 20.8786C17.6809 20.7286 18.0009 20.8187 18.1509 21.0687L18.4609 21.6086C18.6109 21.8586 18.5209 22.1886 18.2709 22.3286C18.1909 22.3786 18.0909 22.3986 18.0009 22.3986H18.011Z" fill="black"/>
                            <path d="M4.01256 18.6494C3.83256 18.6494 3.65254 18.5494 3.55254 18.3794C3.41254 18.1294 3.49255 17.7995 3.75255 17.6595L4.29256 17.3494C4.54256 17.2094 4.86256 17.2895 5.01256 17.5495C5.15256 17.7995 5.07255 18.1294 4.81255 18.2694L4.27254 18.5794C4.19254 18.6294 4.10256 18.6494 4.01256 18.6494Z" fill="black"/>
                            <path d="M21.2244 8.7086C21.0444 8.7086 20.8644 8.61859 20.7644 8.44859C20.6144 8.19859 20.7044 7.86862 20.9544 7.72862L21.4944 7.41869C21.7444 7.26869 22.0744 7.35863 22.2144 7.60863C22.3644 7.85863 22.2744 8.1886 22.0244 8.3286L21.4844 8.63866C21.4044 8.68866 21.3144 8.7086 21.2244 8.7086Z" fill="black"/>
                            <path d="M9.6825 17.249C9.4125 17.249 9.1425 17.1491 8.9325 16.9391C8.5225 16.5291 8.5225 15.8591 8.9325 15.4391L12.0025 12.369C12.4125 11.959 13.0825 11.959 13.5025 12.369C13.9125 12.779 13.9125 13.449 13.5025 13.869L10.4325 16.9391C10.2225 17.1491 9.9525 17.249 9.6825 17.249Z" fill="black"/>
                        </svg>
                        <font className="font-size02 colorGray fontReg ms-3">
                            Publish Date: {startDate}
                        </font>
                    </div>
                </Col>
            </Row>
            <Row className='mt-1 mb-5'>
                <Col xs={12}>
                    <div className='px-20'>
                        <font className="font-size02 colorBlack01 fontLight"><div dangerouslySetInnerHTML={{ __html: txtDetail}}></div></font>
                    </div>
                </Col>
            </Row>
            </ScrollAnimation>
            <ScrollAnimation animateIn='fadeIn'
            initiallyVisible={true}>
            <div className='w-100 d-flex justify-content-center align-items-center'>
                <Row className='w-75-ne'>
                    <Col xs={12} className='px-0 px-lg-3'>
                        <CardSlider6
                            data={listDatasSlider1}
                        />
                        {/* {listDatasSlider1 && listDatasSlider1.length > 0 ? (
                            <CardSlider6
                                data={listDatasSlider1.filter(
                                    item => item && item.images?.length > 0 && item.txtDesc
                                )}
                            />
                        ) : (
                            <p></p>
                        )} */}
                    </Col>
                </Row>
            </div>
            <Row className='mx-10 my-4'>
                <Col xs={12}>
                    <div className="d-flex justify-content-end align-items-center w-100">
                                    <div className="font-size02 fontBold colorBlack text-decoration-none">
                                        SHARE
                                    </div>
                                    <div className='d-flex flex-row align-items-center'>
                                        <div className='w-100 ms-3'>
                                            <FacebookShare url={share} />
                                        </div>
                                        <div className='ms-2 w-100'>
                                        
                                        
                                            <TwitterShare url={share} text={shareXText} />
                                        </div>
                                        <div className='ms-2 w-100'>
                                            <LineShare url={share} text={shareLineText} />
                                        </div>
                                        <div className='ms-2 w-100'>
                                            <LinkCopy url={share}></LinkCopy>
                                        </div>
                                    </div>
                    </div>
                </Col>
            </Row>
            </ScrollAnimation>
            <div>
                <ScrollToTopButton />
            </div>
            <Footer />
        </div>
    </>
  )
}

export default NewEventDetail;