import React, { useRef } from "react";
import Slider from "react-slick";
import "./slick.css";
import { useMediaQueryHook } from "../../hooks/mediaQueryHook";
import { Link, useNavigate } from "react-router-dom";
import FacebookShare from '../../pages/ShareSocial/FacebookShare';
import TwitterShare from '../../pages/ShareSocial/TwitterShare';
import LineShare from '../../pages/ShareSocial/LineShare';
import LinkCopy from '../../pages/ShareSocial/LinkCopy';

const CenterMode = React.memo(({ data }) => {
    const sliderRef = useRef();
    const { viewportSize } = useMediaQueryHook();

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: viewportSize?.width <= 768 ? 1 : 3,
        slidesToScroll: 1,
        arrows: false,
        // centerMode: viewportSize?.width > 1204,
        centerPadding: viewportSize?.width > 1204 ? 80 : 300,
    };

    const next = () => {
        sliderRef.current.slickNext();
    };

    const prev = () => {
        sliderRef.current.slickPrev();
    };

    const navigate = useNavigate();

    const handleSeeMore = (id) => {
        navigate("/promoPrivilege");
    };

    const navigateToDetail = (id, type) => {
        return `/promoPriviDetail?id=${id}&type=${type}`;
    };

    return (
        <div className="slider-container default-font">
            {data.length > 1 ? (
                <Slider ref={sliderRef} {...settings}>
                    {data.map((item, index) => (
                        <div key={index} className="w-100 slide-item" style={{ position: 'relative', userSelect: 'none' }}>
                            <div className="slide">
                                <div className="promo-item position-relative">
                                    <img src={item.images} className="img-style-card01" alt="img" />
                                    <div className="promo-content box-slider">
                                        <div className="d-flex justify-content-center flex-column h-100">
                                            <h2 className="fontBold font-size04"><span className="font-size10 fontBold">{item.discount}</span></h2>
                                            <p className="bd-bottom04"></p>
                                            <p className="fontBold font-size06">{item.txtTitle}</p>
                                            <p className="font-size02 colorBlack01 fontReg mb-0 mb-md-1">
                                                <div className="multi-line-ellipsis02"><div dangerouslySetInnerHTML={{ __html: item.txtDesc}}></div></div>
                                            </p>
                                            {/* <p className="font-size02 colorBlack01 fontBold mb-1 mb-md-2">{item.effectiveDate} - {item.endDate}</p> */}
                                            {/* <p className="font-size02 colorBlack01 fontBold mb-1 mb-md-2">
                                                {item.discountDate == item.endDate
                                                    ? item.discountDate
                                                    : `${item.discountDate} - ${item.endDate}`}
                                            </p> */}
                                            <p className="font-size02 colorBlack01 fontBold mb-1 mb-md-2">
                                            Publish Date: {item.publishDate}
                                            </p>
                                            <p className="font-size03 colorBlack01 fontReg mb-0 mb-md-1">{item.txtDetail}</p>
                                            <font className="colorBlack text-underline fontMed font-size03">
                                                SHARE
                                            </font>
                                            
                                            <div className="d-flex justify-content-start justify-content-md-between flex-column flex-md-row align-items-start align-items-md-center w-100 mt-2">
                                                <div className='d-flex flex-row align-items-center'>
                                                    <div className='w-100 d-flex align-items-end'>
                                                        <FacebookShare url={item.link} />
                                                    </div>
                                                    <div className='ms-2 w-100'>
                                                        <TwitterShare url={item.link} text={item.shareText} />
                                                    </div>
                                                    <div className='ms-2 w-100'>
                                                        <LineShare url={item.link} text={item.shareText} />
                                                    </div>
                                                    <div className='ms-2 w-100'>
                                                        <LinkCopy url={item.link}></LinkCopy>
                                                    </div>
                                                </div>
                                                <div className="mt-2 mt-md-0">
                                                    <Link key={index} to={navigateToDetail(item.idPromo, item.typePromo === 'promo' ? 'promo' : 'priv')} className='btn-see fontMed font-size03 px-1 px-lg-3'>
                                                        SEE MORE
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="promo-title">
                                        <h3>{item.txtTitle}</h3>
                                        <p className="font-size10 fontBold mb-0">{item.discount}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </Slider>
            ) : (
                data.map((item, index) => (
                    <div key={index} className="w-100" style={{ position: 'relative', userSelect: 'none' }}>
                        <div className="row">
                            <div className="col-12 col-xl-6">
                                <img src={item.images} className="cover01" alt="img" />
                            </div>
                            <div className="col-12 col-xl-6 text-dark bg-light-white d-flex flex-column align-items-start p-5">
                            <h2 className="fontBold font-size04"><span className="font-size10 fontBold">{item.discount}</span></h2>
                                        <p className="bd-bottom04"></p>
                                        <p className="fontBold font-size06">{item.txtTitle}</p>
                                        <p className="font-size02 colorBlack01 fontReg mb-0 mb-md-1">
                                            <div className="multi-line-ellipsis02"><div dangerouslySetInnerHTML={{ __html: item.txtDesc}}></div></div>
                                        </p>
                                        <p className="font-size02 colorBlack01 fontBold mb-1 mb-md-1">Publish Date: {item.publishDate}</p>
                                        <p className="font-size03 colorBlack01 fontReg mb-0 mb-md-1">{item.txtDetail}</p>
                                        <font className="colorBlack text-underline fontMed font-size03">
                                            SHARE
                                        </font>
                                        
                                        <div className="d-flex justify-content-start justify-content-md-between flex-column flex-md-row align-items-start align-items-md-center w-100 mt-2">
                                            <div className='d-flex flex-row align-items-center'>
                                                <div className='w-100'>
                                                    <FacebookShare url={item.link} />
                                                </div>
                                                <div className='ms-2 w-100'>
                                                    <TwitterShare url={item.link} text={item.shareText} />
                                                </div>
                                                <div className='ms-2 w-100'>
                                                    <LineShare url={item.link} text={item.shareText} />
                                                </div>
                                                <div className='ms-2 w-100'>
                                                    <LinkCopy url={item.link}></LinkCopy>
                                                </div>
                                            </div>
                                            <div className="mt-2 mt-md-0">
                                                <Link key={index} to={navigateToDetail(item.idPromo, item.typePromo === 'promo' ? 'promo' : 'priv')} className='btn-see fontMed font-size03 px-1 px-lg-3'>
                                                    SEE MORE
                                                </Link>
                                            </div>
                                        </div>
                            </div>
                        </div>
                    </div>
                ))
            )}
            {data.length > 1 && (
                <div className="container">
                    <div className="row mt-5">
                        <div className="col-3 d-flex justify-content-start mt-3">
                            <svg onClick={prev} type="button" width={49} height={49} viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M24.7892 47.7397C37.6795 47.7397 48.1292 37.2902 48.1292 24.3999C48.1292 11.5096 37.6795 1.06006 24.7892 1.06006C11.8989 1.06006 1.44922 11.5096 1.44922 24.3999C1.44922 37.2902 11.8989 47.7397 24.7892 47.7397Z" fill="white" stroke="#BBBDBF" strokeMiterlimit={10} />
                                <path d="M26.8604 28.5298L22.7305 24.3999L26.8604 20.27" fill="white" />
                                <path d="M26.8604 28.5298L22.7305 24.3999L26.8604 20.27" stroke="black" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            &nbsp;&nbsp;
                            <svg onClick={next} type="button" width={49} height={49} viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M24.3283 47.7397C37.2186 47.7397 47.6683 37.2902 47.6683 24.3999C47.6683 11.5096 37.2186 1.06006 24.3283 1.06006C11.438 1.06006 0.988281 11.5096 0.988281 24.3999C0.988281 37.2902 11.438 47.7397 24.3283 47.7397Z" fill="white" stroke="#BBBDBF" strokeMiterlimit={10} />
                                <path d="M22.2695 28.5298L26.3995 24.3999L22.2695 20.27" fill="white" />
                                <path d="M22.2695 28.5298L26.3995 24.3999L22.2695 20.27" stroke="black" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </div>
                        <div className="col-9 d-flex justify-content-end mt-3">
                            <button className="btn border d-flex rounded-pill align-items-center" onClick={() => handleSeeMore()}>
                                <div className="fontMed font-size02 px-1 px-lg-3">SEE MORE</div>
                                <div className="ms-4">
                                    <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M13.499 26.5802C20.6566 26.5802 26.459 20.7778 26.459 13.6202C26.459 6.46255 20.6566 0.660156 13.499 0.660156C6.34141 0.660156 0.539062 6.46255 0.539062 13.6202C0.539062 20.7778 6.34141 26.5802 13.499 26.5802Z" fill="black" />
                                        <path d="M12.8594 9.68018L16.7994 13.6202L12.8594 17.5602" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
})

export default CenterMode;